import React from "react"

import Layout from "../components/Layout"
import TimetableNotice from "../components/Content/TimetableNotice"
import Heading from "../components/Layout/heading"
import QuoteForm from "../components/Forms/quote"

import SEO from "../components/SEO"

const Quote = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Get a Quote"
      description="Available for Charter - Anytime - Anywhere"
    />
    <TimetableNotice />
    <div className="row">
      <div className="col-12 col-md-6 col-lg-8">
        <Heading title="Available for Charter - Anytime - Anywhere" />
        <p className="sub-heading">
          Fully accommodated, modern air-conditioned coaches including:
        </p>
        <ul>
          <li>TV</li>
          <li>DVD</li>
          <li>Seat Belts</li>
          <li>Toilet Facilities</li>
        </ul>
      </div>
      <div className="col-12 col-md-6 col-lg-4">
        <Heading title="Get a Quote" />
        <QuoteForm />
      </div>
    </div>
  </Layout>
)

export default Quote
