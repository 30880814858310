import React, { useState } from "react"
import Axios from "axios"
import Recaptcha from "react-recaptcha"
import Button from "react-bootstrap/Button"

import useForm from "./Hooks/useForm"
import validate from "./Validation/Quote"

import Message from "../Message"
import Icon from "../Icon"
import Feedback from "./Feedback"

import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"

import "./styles.scss"

const API = process.env.API
const recaptchaKey = process.env.GOOGLE_RECAPTCHA_KEY
let recaptchaInstance

const QuoteForm = () => {
  const [message, setMessage] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    handleChange,
    handleDateChange,
    handleSubmit,
    handleReCaptcha,
    resetReCaptcha,
    values,
    errors,
  } = useForm(submit, validate)

  function submit() {
    setMessage({})
    setIsSubmitting(true)
    Axios.post(`${API}/forms/quote`, {
      name: values.name,
      phone: values.phone,
      email: values.email,
      peopleAmount: values.peopleAmount,
      vehicleSize: values.vehicleSize,
      departureDate: values.departureDate.toString(),
      returnDate: values.returnDate.toString(),
      pickup: values.pickup,
      destination: values.destination,
      message: values.message,
      reCaptcha: values.reCaptcha,
    })
      .then(() => {
        setIsSubmitting(false)
        setMessage({
          type: "success",
          text: "Your enquiry has been sent",
        })
        resetReCaptcha(recaptchaInstance)
      })
      .catch(() => {
        setIsSubmitting(false)
        setMessage({
          type: "danger",
          text: "Your enquiry failed to send",
        })
        resetReCaptcha(recaptchaInstance)
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <input
                onChange={handleChange}
                name="name"
                className="form-control"
                placeholder="Name"
                aria-label="Full Name"
              />
              <Feedback message={errors.name} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <input
                onChange={handleChange}
                name="phone"
                className="form-control"
                placeholder="Phone"
                aria-label="Phone Number"
              />
              <Feedback message={errors.phone} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <input
                onChange={handleChange}
                name="email"
                className="form-control"
                placeholder="E-Mail"
                aria-label="Email Address"
              />
              <Feedback message={errors.email} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <input
                onChange={handleChange}
                name="peopleAmount"
                className="form-control"
                placeholder="People Travelling"
                aria-label="Amount of People Travelling"
              />
              <Feedback message={errors.peopleAmount} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <select
                onBlur={handleChange}
                name="vehicleSize"
                className="form-control"
              >
                <option>Vehicle Size</option>
                <option>20 Seat Coaster</option>
                <option>24 Seat Rosa</option>
                <option>48 Seat Coach</option>
                <option>53 Seat Coach</option>
                <option>57 Seat Semi Coach</option>
                <option>61 Seat School Bus</option>
              </select>
              <Feedback message={errors.vehicleSize} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <Datetime
                value={values.departureDate || "Departure Date & Time"}
                onChange={(input) => {
                  handleDateChange(input, "departureDate")
                }}
                dateFormat="dddd Do MMMM YYYY @"
                timeFormat="hh:mma"
              />
              <Feedback message={errors.departureDate} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <Datetime
                value={values.returnDate || "Return Date & Time"}
                onChange={(input) => {
                  handleDateChange(input, "returnDate")
                }}
                dateFormat="dddd Do MMMM YYYY @"
                timeFormat="hh:mma"
              />
              <Feedback message={errors.returnDate} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <input
                onChange={handleChange}
                name="pickup"
                className="form-control"
                placeholder="Pickup Location"
                aria-label="Pickup Location"
              />
              <Feedback message={errors.pickup} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <input
                onChange={handleChange}
                name="destination"
                className="form-control"
                placeholder="Destination"
                aria-label="Destination"
              />
              <Feedback message={errors.destination} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <textarea
                onChange={handleChange}
                name="message"
                className="form-control"
                rows="6"
                placeholder="Other Details"
                aria-label="Other Details"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <div className="form-group">
              <Recaptcha
                ref={(e) => (recaptchaInstance = e)}
                sitekey={recaptchaKey}
                render="explicit"
                onloadCallback={() => {}}
                verifyCallback={(response) => {
                  handleReCaptcha(response)
                }}
              />
              <Feedback message={errors.reCaptcha} />
            </div>
            <Message type={message.type} message={message.text} />
            <div className="form-group">
              {isSubmitting ? (
                <Button type="submit" disabled bsPrefix="stu">
                  Sending <Icon prefix="fas" name="spinner" spin />
                </Button>
              ) : (
                <Button type="submit" onClick={handleSubmit} bsPrefix="stu">
                  Send <Icon prefix="fas" name="chevron-right" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default QuoteForm
