import { useState, useEffect } from "react"

const useForm = (callback, validate) => {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})
  const [canSubmit, setCanSubmit] = useState(false)

  const handleChange = (event) => {
    const { name, value } = event.target
    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleCheckboxChange = (event) => {
    const { name } = event.target
    setValues({
      ...values,
      [name]: Number(!values[name]),
    })
  }

  const handleDateChange = (input, field) => {
    setValues({
      ...values,
      [field]: input,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validate(values))
    setCanSubmit(true)
  }

  const handleReCaptcha = (response) => {
    setValues((prevValues) => ({ ...prevValues, reCaptcha: response }))
  }

  const resetReCaptcha = (recaptchaInstance) => {
    setValues((prevValues) => ({ ...prevValues, reCaptcha: "" }))
    recaptchaInstance.reset()
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && canSubmit) {
      callback()
      setCanSubmit(false)
    }
    // eslint-disable-next-line
  }, [errors])

  return {
    handleChange,
    handleCheckboxChange,
    handleDateChange,
    handleSubmit,
    handleReCaptcha,
    resetReCaptcha,
    values,
    errors,
  }
}

export default useForm
