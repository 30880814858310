export default function validateQuote(values) {
  let errors = {}

  if (!values.name) {
    errors.name = "Please enter your Name"
  }

  if (!values.phone) {
    errors.phone = "Please enter your Phone Number"
  }

  if (!values.email) {
    errors.email = "Please enter your Email"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email is invalid"
  }

  if (!values.peopleAmount) {
    errors.peopleAmount = "Please enter the amount of People Travelling"
  }

  if (!values.vehicleSize) {
    errors.vehicleSize = "Please select a Vehicle Size"
  }

  if (!values.departureDate) {
    errors.departureDate = "Please select a Departure Date"
  }

  if (!values.returnDate) {
    errors.returnDate = "Please select a Return Date"
  }

  if (!values.pickup) {
    errors.pickup = "Please enter a Pickup Location"
  }

  if (!values.destination) {
    errors.destination = "Please enter a Destination"
  }

  if (!values.reCaptcha) {
    errors.reCaptcha = "Please verify you are a human"
  }

  return errors
}
